import { getRequest } from '@/api/axios'

// 获取信息动态id
export const getAgricultureID = (params) => {
  return getRequest('/blade-integrate/openapi/integratecolumninfo/list', params)
}

// 获取信息动态下的栏目信息
export const getInforID = (params) => {
  return getRequest('/blade-integrate/openapi/integratelabelinfo/labelListByColumnId', params)
}

// 数据栏目id拿数据
export const getInforList = (params) => {
  return getRequest('/blade-integrate/openapi/integrateinformation/list', params)
}

<template>
  <div class="container">
    <SecondNav></SecondNav>
    <div class="inforDynamic flex">
      <div class="inforDynamicLeft">
        <div>
          <h1>{{inforTitle}}</h1>
          <p>Information Disclosure</p>
        </div>
        <ul>
          <li v-for="(item,idx) in column" :key="idx" :class="index==idx?'active':''" @click="son(item,idx)">{{item.labelName}}</li>
        </ul>
      </div>
      <div class="inforDynamicRight">
        <div class="inforDynamicRightTop">
          <h1>{{columnTitle}}</h1>
        </div>
        <div class="inforDynamicRightBottom">
          <ul>
            <li v-for="(item,idx) in list" :key="idx">
              <p><a href="#">{{item.title}}</a></p>
              <p class="time">{{item.createTime}}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="Pagination">
      <ul class="flex">
        <li v-if="cur>1"><a @click="cur--,pageClick()">上一页</a></li>
        <li v-if="cur==1"><a class="banclick">上一页</a></li>
        <li v-for="idx in indexs" :key="idx" :class="{ 'active': cur == idx}">
        <a @click="btnClick(idx)">{{ idx }}</a>
        </li>
        <li v-if="cur!=all"><a @click="cur++,pageClick()">下一页</a></li>
        <li v-if="cur == all"><a class="banclick">下一页</a></li>
        <li><a>共<i>{{all}}</i>页</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getAgricultureID, getInforID, getInforList } from '@/api/informationdynamic'
import SecondNav from '../about/components/secondNav'
export default {
  components: {
    SecondNav
  },
  data () {
    return {
      inforTitle: '',
      columnTitle: '',
      column: '',
      list: '',
      index: 0,
      lblid: '',
      index1: 0,
      all: 10, //总页数
      cur: 1,//当前页码
      totalPage: 0//当前条数
    }
  },
  methods: {
    getinfor () {
      getAgricultureID({
        columnName: '信息动态'
      }).then((res) => {
        this.inforTitle = res.data.records[0].columnName
        const cid = res.data.records[0].id
        getInforID({
          columnId: cid
        }).then((res) => {
          this.column = res.data
          this.columnTitle = res.data[0].labelName
          const lid = res.data[0].id
          this.lblid=lid
          getInforList({
            labelId: lid,
            size: 15,
            current: this.cur
          }).then((res) => {
            this.all=res.data.pages
            this.cur=res.data.current
            this.list = res.data.records
          })
        })
      })
    },
    son (item, idx) {
      this.index = idx
      this.lblid=item.id
      this.columnTitle=item.labelName
      getInforList({
        labelId: item.id,
        size: 15
      }).then((res) => {
        this.all=res.data.pages
        this.cur=res.data.current
        this.list = res.data.records
      })
    },
    son1 (item, idx) {
      getInforList({
        labelId: this.lblid,
        size: 15,
        current: idx+1
      }).then((res) => {
        this.index1=idx
        this.all=res.data.pages
        this.cur=res.data.current
        this.list = res.data.records
      })
    },
    dataList (idx) {
       getInforList({
        labelId: this.lblid,
        size: 15,
        current: idx
      }).then((res) => {
        this.list=res.data.records
        this.all=res.data.pages
        this.cur=res.data.current
        this.titalPage=res.data.total
      })
    },
    btnClick(data){
      if(data != this.cur){ //判断是不是当前页，不是就计算
        this.cur = data
      }
      this.dataList(this.cur.toString())
    },
    pageClick () {
      this.dataList(this.cur.toString())
    }
  },
  mounted () {
    this.getinfor()
  },
  computed: {
    indexs() {
        var left = 1; //默认起始值是1
        var right = this.all; //终止值是全部
        var arr = [];
        if(this.all>= 5){
            if(this.cur > 3 && this.cur < this.all-2){
                    left = this.cur - 2
                    right = this.cur + 2
            }else{
                if(this.cur<=3){
                    left = 1
                    right = 5
                }else{
                    right = this.all
                    left = this.all -4
                }
            }
        }
        while (left <= right){
            arr.push(left)
            left ++
        }
        return arr
    }
  }
}
</script>

<style>
.inforDynamic{
    margin-top: 20px;
}
.inforDynamicLeft{
    width: 300px;
    font-size: 16px;
}
.inforDynamicLeft{
    padding: 20px 10px 20px;
    margin: 10px 0;
    box-shadow: 0px 0px 10px 2px rgb(0 0 0 / 10%);
    height: 100%;
}
.inforDynamicLeft h1{
    font-size: 24px;
    color: #93b067;
}
.inforDynamicLeft p{
    color: #999;
    font-size: 16px;
}
.inforDynamicLeft .active{
    background-color: #c8e5b8;
    color: #00f;
}
.inforDynamicLeft li{
    padding: 10px 0;
    background-color: #f5f5f5;
    margin: 10px 0;
    cursor: pointer;
    font-size: 16px;
    padding-left:10px;
}
.inforDynamicRight{
    padding-left: 20px;
    width: 100%;
}
.inforDynamicRightTop{
    padding: 15px 0 0;
    width: 100%;
    margin: 3px 0 10px;
}
.inforDynamicRight h1{
    font-weight: normal;
    position: relative;
    text-align: left;
    margin-bottom: 10px;
}
.inforDynamicRight h1::before{
    content: "";
    width: 5px;
    height: 20px;
    display: inline-block;
    background: #93b067;
    margin-right: 15px;
}
.inforDynamicRightBottom{
    padding: 0 20px;
}
.inforDynamicRight li{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #ccc;
    padding: 15px 0;
}
.inforDynamicRight .active{
    color: #63c8f1;
    text-decoration: underline;
}
.inforDynamicRight .time {
  color: #aaa;
}
.Pagination{
  margin: 40px auto;
  margin-top:50px;
}
.Pagination ul{
  justify-content: center;
}
.Pagination li:first-child>a{
  margin-left: 0px;
}
.Pagination a{
  border: 1px solid #000;
  text-decoration: none;
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  color: #5d6062;
  cursor: pointer;
  margin-right: 20px;
}
.Pagination a:hover{
  background-color: #eee;
}
.Pagination a.banclick{
  cursor: not-allowed;
}
.Pagination .active a{
  color: #fff;
  cursor: default;
  background-color: #e96463;
  border-color: #e96463;
}
.Pagination i{
  font-style: normal;
  color: #d44950;
  margin: 0px 4px;
  font-style: 12px;
}
</style>
